import React from 'react'
import Header from '../Components/Header';
import { Container, Row, Col } from 'react-bootstrap';
import { MdOutlineWeb } from 'react-icons/md';
import { RiPencilRulerLine } from 'react-icons/ri';
import { HiOutlineGlobeAlt } from 'react-icons/hi';
import { FiShare2 } from 'react-icons/fi';

const Service = () => {
  const iconSize = '100%';

  return (
    <>
      <Header topic={'บริการของเรา'} detail={'เรามุ่งมั่นในการเป็นพันธมิตรที่เชื่อถือได้ในการพัฒนาและเสริมสร้างธุรกิจของคุณ ด้วยบริการครบวงจร'} />
      <Container>
        <Row className='pt-5 mt-5'>
          <Col md={4} className='px-5'>
            <HiOutlineGlobeAlt size={iconSize} color="#FA717B" />
          </Col>
          <Col>
            <h3>Website Development</h3>
            <p>เรามอบบริการพัฒนาเว็บไซต์ที่ตอบสนองทุกความต้องการของธุรกิจคุณ ด้วยการออกแบบที่ทันสมัย ฟังก์ชันการใช้งานครบครัน และบริการหลังการขายที่ช่วยให้การดูแลเว็บไซต์เป็นเรื่องง่ายและมีประสิทธิภาพ</p>
            <ul className='m-0 mb-3 p-0 px-4'>
              <li>
                <b>Responsive Design</b> เว็บไซต์ของเรารองรับการแสดงผลที่สวยงามและสมบูรณ์แบบบนทุกอุปกรณ์ ไม่ว่าจะเป็นคอมพิวเตอร์ แท็บเล็ต หรือสมาร์ทโฟน เพื่อให้ผู้ใช้งานเข้าถึงได้ทุกที่ทุกเวลา
              </li>
              <li>
                <b>Multilingual</b> รองรับการใช้งานหลายภาษา สามารถปรับแต่งภาษาให้เหมาะสมกับกลุ่มเป้าหมายทั่วโลกได้อย่างง่ายดาย
              </li>
              <li>
                <b>ระบบหลังบ้านที่ใช้งานง่าย</b> เราออกแบบระบบจัดการเนื้อหา (CMS) ที่ใช้งานง่าย ช่วยให้คุณสามารถอัปเดตข้อมูลหรือเนื้อหาบนเว็บไซต์ได้ด้วยตัวเองโดยไม่ต้องมีความรู้ด้านเทคนิค
              </li>
              <li>
                <b>การเตรียมข้อมูลและเนื้อหา</b> เราช่วยเตรียมเนื้อหาและโครงสร้างข้อมูลให้พร้อมเพื่อให้เว็บไซต์ของคุณมีข้อมูลที่ชัดเจน ถูกต้อง และสอดคล้องกับความต้องการของธุรกิจ
              </li>
              <li>
                <b>บริการ Hosting และการดูแลเว็บไซต์</b> เรามีบริการโฮสติ้งพร้อมทีมงานที่คอยดูแลเว็บไซต์ของคุณอย่างต่อเนื่อง เพื่อให้มั่นใจได้ว่าเว็บไซต์จะออนไลน์ตลอดเวลา พร้อมการแก้ไขปัญหาอย่างรวดเร็ว
              </li>
            </ul>
          </Col>
        </Row>

        <Row className='pt-5 mt-5 pb-5 mb-5'>
          <Col>
            <h3>Custom Software Development</h3>
            <p>เราพัฒนาซอฟต์แวร์เฉพาะทางที่ออกแบบมาเพื่อตอบโจทย์ความต้องการของธุรกิจของคุณ ไม่ว่าจะเป็นระบบ ERP, ระบบออกบิล, ระบบออกรายงาน และระบบอื่น ๆ ตามที่คุณต้องการ ทีมงานผู้เชี่ยวชาญของเราพร้อมทำงานร่วมกับคุณตั้งแต่ขั้นตอนวิเคราะห์ความต้องการ ออกแบบโครงสร้าง จนถึงการพัฒนาซอฟต์แวร์ เพื่อให้แน่ใจว่าได้ระบบที่ตอบโจทย์และสามารถยกระดับประสิทธิภาพการดำเนินงานของธุรกิจคุณ</p>
            <ul className='m-0 mb-3 p-0 px-4'>
              <li>
                <b>ระบบ ERP (Enterprise Resource Planning)</b> ระบบ ERP ช่วยจัดการทุกส่วนของธุรกิจในแพลตฟอร์มเดียว ไม่ว่าจะเป็นการบริหารทรัพยากรบุคคล การเงิน การผลิต การจัดการสินค้าคงคลัง และการจัดการลูกค้าสัมพันธ์ (CRM) ระบบนี้ช่วยให้การบริหารจัดการเป็นเรื่องง่าย ลดความซับซ้อนในการดำเนินงาน เพิ่มประสิทธิภาพ และประหยัดเวลาในการทำงานอย่างมาก
              </li>
              <li>
                <b>ระบบออกบิล</b> เราพัฒนาระบบออกบิลที่สามารถสร้างใบแจ้งหนี้และใบเสร็จได้อย่างรวดเร็วและถูกต้อง ระบบนี้สามารถเชื่อมต่อกับฐานข้อมูลสินค้าหรือบริการ คำนวณภาษีอัตโนมัติ และรองรับการชำระเงินหลายรูปแบบ เพื่อให้กระบวนการออกบิลมีความรวดเร็วและลดข้อผิดพลาด ทำให้การจัดการการเงินของคุณมีประสิทธิภาพยิ่งขึ้น
              </li>
              <li>
                <b>ระบบออกรายงาน</b> ระบบออกรายงานที่พัฒนาโดยเราช่วยให้คุณสามารถสร้างรายงานในรูปแบบต่าง ๆ ได้ตามความต้องการ ไม่ว่าจะเป็นรายงานการขาย รายงานการเงิน รายงานสต็อกสินค้า หรือรายงานอื่น ๆ ที่มีความสำคัญต่อธุรกิจ ระบบนี้มีความยืดหยุ่นและสามารถดึงข้อมูลจากฐานข้อมูลของคุณมาวิเคราะห์และแสดงผลในรูปแบบที่เข้าใจง่าย ช่วยให้คุณตัดสินใจทางธุรกิจได้อย่างมีข้อมูลและแม่นยำ
              </li>
            </ul>
            <p>บริการพัฒนาซอฟต์แวร์ของเราจะช่วยยกระดับธุรกิจของคุณด้วยระบบที่ออกแบบมาเพื่อให้ใช้งานง่าย ครอบคลุมทุกความต้องการ และช่วยเพิ่มประสิทธิภาพในการทำงานได้อย่างสูงสุด</p>
          </Col>
          <Col md={4} className='px-5'>
            <MdOutlineWeb size={iconSize} color="#1D96D3" />
          </Col>
        </Row>

        <Row className='pt-5 mt-5 pb-5 mb-5'>
          <Col md={4} className='px-5'>
            <RiPencilRulerLine size={iconSize} color="#FBAE18" />
          </Col>
          <Col>
            <h3>Graphic Design</h3>
            <p>บริการออกแบบกราฟิกที่หลากหลาย ครอบคลุมทั้งการออกแบบเว็บไซต์, โลโก้, แบนเนอร์, และการนำเสนอ (Presentation) เรามุ่งเน้นการสร้างสรรค์งานที่มีความโดดเด่น สะท้อนถึงเอกลักษณ์ของแบรนด์ และสื่อสารได้อย่างมีประสิทธิภาพ ทีมงานมืออาชีพของเราจะทำงานร่วมกับคุณเพื่อสร้างผลงานที่สามารถดึงดูดความสนใจและสร้างความประทับใจให้กับกลุ่มเป้าหมายของคุณ โดยเน้นไปที่ความคมชัดของดีไซน์ และการนำเสนอที่สอดคล้องกับเป้าหมายทางธุรกิจของคุณ</p>
          </Col>
        </Row>

        <Row className='pt-5 mt-5 pb-5 mb-5'>
          <Col>
            <h3>Social Media Administration</h3>
            <p>เราบริการดูแลและจัดการสื่อสังคมออนไลน์ (Social Media) อย่างครบวงจร ตั้งแต่การเขียนเนื้อหาบทความที่น่าสนใจ ไปจนถึงการอัปเดตข้อมูลตามความต้องการของลูกค้า ทีมงานของเราช่วยให้ธุรกิจของคุณมีการปรากฏตัวที่โดดเด่นและสม่ำเสมอบนแพลตฟอร์มออนไลน์ต่าง ๆ โดยเน้นการสื่อสารที่ตรงกลุ่มเป้าหมาย เพื่อเพิ่มการมีส่วนร่วม (engagement) และสร้างภาพลักษณ์ที่น่าสนใจในโลกดิจิทัล</p>
          </Col>
          <Col md={4} className='px-5'>
            <FiShare2 size={iconSize} color="#38D295" />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Service