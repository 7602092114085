import React from 'react';
import { Link } from 'react-router-dom';
import FreshImage from '../Components/FreshImage';
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhoneCall, FiMapPin } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <footer className="footer ptb-120">
        <div className="container">
          <div className="row">

            <div className="col-md-4">
              <div className="who">
                <FreshImage src="/img/logo/logo.webp" alt="logo" className="footer-logo mb-25" />
                <p className="p-3">บริษัท อินครีชั่น จำกัด ยินดีให้คำปรึกษาเรื่องซอฟต์แวร์ สำหรับผู้ที่ต้องการทำเว็บไซต์ ผู้ที่ต้องการทำซอฟต์แวร์เฉพาะทางใช้ภายในองค์กรของท่าน</p>
              </div>
            </div>

            <div className="col-md-2">
              <div className="links">
                <h4 className="h4 mb-20">Links</h4>
                <ul>
                  <li>
                    <Link to="/Article/services">บริการของเรา</Link>
                  </li>
                  <li>
                    <Link to="/Article/about-us">เกี่ยวกับเรา</Link>
                  </li>
                  <li>
                    <Link to="/Portfolio">ผลงาน</Link>
                  </li>
                  <li>
                    <Link to="/Article">บทความ</Link>
                  </li>
                  <li>
                    <Link to="/Contact">ติดต่อเรา</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="links">
                <h4 className="h4 mb-20">Help</h4>
                <ul>
                  <li>
                    <Link to="/Article/faq">คำถามที่พบบ่อย</Link>
                  </li>
                  <li>
                    <Link to="/Article/terms-and-conditions">ข้อตกลงและเงื่อนไข</Link>
                  </li>
                  <li>
                    <Link to="/Article/privacy-policy">นโยบายความเป็นส่วนตัว</Link>
                  </li>
                  <li>
                    <Link to="/Article/cookies-policy">นโยบายด้านคุกกี้</Link>
                  </li>
                  <li>
                    <Link to="/Login">ส่วนของลูกค้า</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="social">
                <h4 className="h4 mb-20">ติดต่อเรา</h4>
                <ul className="list-nopadding footer-font">
                  <li className="mb-2 d-flex flex-row">
                    <div className='mr-2'><FaHome /></div>
                    <div className="d-inline-block">144/166 โครงการเดอะคอนเน็กอัพ3 (ซอย9) ซอยนาวีเจริญทรัพย์
                      ถนนกาญจนาภิเษก แขวงบางแค เขตบางแค กรุงเทพมหานคร 10160</div>
                  </li>
                  <li className="mb-2 d-flex flex-row justify-content-center justify-content-md-start">
                    <div className='mr-2'><HiOutlineMail /></div>
                    <div className="d-inline-block">
                      <a href="mailto:increxion@gmail.com">increxion@gmail.com</a>
                    </div>
                  </li>
                  <li className="mb-2 d-flex flex-row justify-content-center justify-content-md-start">
                    <div className='mr-2'><FiPhoneCall /></div>
                    <div className="d-inline-block">
                      <a href="tel:095-952-4556">095-952-4556</a>
                    </div>
                  </li>
                  <li className="mb-2 d-flex flex-row justify-content-center justify-content-md-start">
                    <div className='mr-2'><FiMapPin /></div>
                    <div className="d-inline-block">
                      <a href="https://goo.gl/maps/DwbvAhR4KZ4VdcMr9" target="_blank" rel="noreferrer">แผนที่</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="bottom text-center">
        <span>© Increxion Co., Ltd. {new Date().getFullYear()} All rights reserved.</span>
      </div>
    </>
  );
}

export default Footer;
