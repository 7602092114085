import React, { useState } from 'react';

const FreshImage = (props) => {
  const [version] = useState('1.0.2');

  return (
    <>
      <img {...props} src={props.src + "?v=" + version} alt={props.alt} />
    </>
  );
};

export default FreshImage;
