import React, { Component } from 'react'

export class UnderDevelop extends Component {
  render() {
    return (
      <>
        <section className="slider d-flex align-items-center">
          <div className="shapes">
            <div className="shape-1"><img src="_theme/img/shapes/shape-1.svg" alt="shape" /></div>
            <div className="shape-2"><img src="_theme/img/shapes/shape-2.svg" alt="shape" /></div>
            <div className="shape-3"><img src="_theme/img/shapes/shape-3.svg" alt="shape" /></div>
            <div className="shape-4"><img src="_theme/img/shapes/shape-4.svg" alt="shape" /></div>
            <div className="shape-5"><img src="_theme/img/shapes/shape-5.svg" alt="shape" /></div>
            <div className="shape-6"><img src="_theme/img/shapes/shape-6.svg" alt="shape" /></div>
            <div className="shape-7"><img src="_theme/img/shapes/shape-7.svg" alt="shape" /></div>
            <div className="shape-8"><img src="_theme/img/shapes/shape-8.svg" alt="shape" /></div>
          </div>
          <div className="container">
            <div className="row d-flex align-items-lg-center">
              <div className="col-md-12">
                <div className="content">
                  <h2 className="h2 mb-10">ขออภัย เว็บไซต์ส่วนนี้อยู่ระหว่างการพัฒนา</h2>
                  <p className="p-1 mb-20">หากต้องการข้อมูลเพิ่มเติม ปรึกษาเรื่องพัฒนาเว็บไซต์ แอพพลิเคชั่น ติดต่อเราง่ายๆโดยคลิ๊กที่ปุ่มด้านล่าง</p>
                  <a href="tel:095-952-4556" className="btn-blue">ติดต่อเรา</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default UnderDevelop