import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineWeb, MdOutlineDevices, MdOutlineMapsHomeWork } from 'react-icons/md';
import { RiPencilRulerLine, RiReactjsFill, RiVuejsFill } from 'react-icons/ri';
import { HiOutlineGlobeAlt, HiOutlineLightBulb } from 'react-icons/hi';
import { FiShare2 } from 'react-icons/fi';
import { BiMessageRoundedCheck } from 'react-icons/bi';
import { TbMessage2Code } from 'react-icons/tb';
import { FaPhp } from 'react-icons/fa';
import { SiStrapi } from 'react-icons/si';
import { AiOutlineSketch } from 'react-icons/ai';
import { ImStatsDots } from 'react-icons/im';
import { DiMysql, DiJqueryLogo, DiJqueryUiLogo, DiHtml5, DiJavascript, DiCss3, DiNodejsSmall, DiLaravel } from 'react-icons/di';

const Home = () => {
  const [techIcon, setTechIcon] = useState({
    size: '4em',
    color: '#FFF',
  });
  const [articles, setArticles] = useState([]);

  return (
    <>
      <section className="slider d-flex align-items-center">
        <div className="shapes">
          <div className="shape-1"><img src="_theme/img/shapes/shape-1.svg" alt="shape" /></div>
          <div className="shape-2"><img src="_theme/img/shapes/shape-2.svg" alt="shape" /></div>
          <div className="shape-3"><img src="_theme/img/shapes/shape-3.svg" alt="shape" /></div>
          <div className="shape-4"><img src="_theme/img/shapes/shape-4.svg" alt="shape" /></div>
          <div className="shape-5"><img src="_theme/img/shapes/shape-5.svg" alt="shape" /></div>
          <div className="shape-6"><img src="_theme/img/shapes/shape-6.svg" alt="shape" /></div>
          <div className="shape-7"><img src="_theme/img/shapes/shape-7.svg" alt="shape" /></div>
          <div className="shape-8"><img src="_theme/img/shapes/shape-8.svg" alt="shape" /></div>
        </div>
        <div className="container">
          <div className="row d-flex align-items-lg-center">
            <div className="col-md-6">
              <div className="content">
                <h2 className="h2 mb-10">บริการออกแบบและพัฒนาเว็บไซต์ ซอฟต์แวร์ แอพพลิเคชั่น</h2>
                <p className="p-1 mb-20">เรายินดีให้คำปรึกษาในการพัฒนาฟีเจอร์ต่างๆตามความต้องการของลูกค้า ให้ซอฟต์แวร์ที่ลูกค้าต้องการตรงกับความต้องการมากที่สุด</p>
                <a href="tel:095-952-4556" className="btn-blue">ติดต่อเรา</a>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="animate-levitation">
                <TbMessage2Code size="80%" color="#30C3D5" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-work ptb-120">
        <div className="container text-center">
          <div className="heading text-center mb-70">
            <h2 className="h2">บริการของเรา</h2>
            <p className="p-2">เราให้บริการด้านการพัฒนาซอฟต์แวร์ และงานออกแบบต่างๆ<br />ให้กับองค์กร, บริษัท, ธุรกิจ SMEs และส่วนบุคคล</p>
          </div>
          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="box">
                <HiOutlineGlobeAlt className="mb-10" size="5em" color="#FA717B" />
                <h4 className="h4 mb-10">Website</h4>
                <p className="p-2">พัฒนาเว็บไซต์ รองรับการแสดงผลแบบ Responsive พร้อมระบบหลังบ้านสำหรับปรับปรุงข้อมูลบนเว็บไซต์ได้เอง</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="box">
                <MdOutlineWeb className="mb-10" size="5em" color="#1D96D3" />
                <h4 className="h4 mb-10">Software</h4>
                <p className="p-2">พัฒนาระบบตามความต้องการของลูกค้า อาทิเช่น ระบบออกบิล, ระบบคำนวณต้นทุน, ระบบออกรายงานต่างๆ เป็นต้น</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="box">
                <RiPencilRulerLine className="mb-10" size="5em" color="#FBAE18" />
                <h4 className="h4 mb-10">Graphic Design</h4>
                <p className="p-2">ออกแบบและสร้างสรรค์งานกราฟฟิกต่างๆ ไม่ว่าจะเป็น Website, Logo, Banner, Presentation เป็นต้น</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="box">
                <FiShare2 className="mb-10" size="5em" color="#38D295" />
                <h4 className="h4 mb-10">Social Media Admin</h4>
                <p className="p-2">ดูแลเว็บไซต์และ Social Media เขียน Content บทความต่างๆ และอัพเดตข้อมูลตามความต้องการของลูกค้า</p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <Link to="/Service"><button className="btn btn-primary bg-color-01">รายละเอียดเพิ่มเติม</button></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="company pb-120">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 text-center">
              <MdOutlineMapsHomeWork size="70%" color="#919CAC" />
            </div>
            <div className="col-md-6">
              <div className="text text-1">
                <h3 className="h3 mb-20">รู้จักกับ บริษัท อินครีชั่น จำกัด</h3>
                <p className="p-2 mb-30">เราเริ่มให้บริการด้านซอฟต์แวร์ในปี 2016 (กว่า {(new Date().getFullYear() + 543) - 2559} ปี)
                  เชี่ยวชาญในการพัฒนาเว็บไซต์ และเว็บแอพพลิเคชั่น เราออกแบบและพัฒนาซอฟต์แวร์โดยคำนึงถึงความต้องการของลูกค้าเป็นหลัก เพื่อให้มั่นใจว่าลูกค้าจะได้รับความพึงพอใจสูงสุดในระบบที่เราสร้าง ทีมงานของเราวิเคราะห์ความต้องการอย่างละเอียดและรอบคอบ พร้อมทั้งใส่ใจในรายละเอียดที่ลูกค้าอาจไม่ได้ระบุแต่เป็นสิ่งสำคัญสำหรับการใช้งานจริง เพื่อให้ซอฟต์แวร์ที่เราพัฒนาสามารถตอบโจทย์และเพิ่มคุณค่าให้กับธุรกิจของลูกค้าได้อย่างครบถ้วน</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="check">
        <div className="container text-center">
          <div className="content">
            <h2 className="h2-white mb-15">รับข่าวสารจากเรา</h2>
            <p className="p-white mb-70">Submit Email กับเราเพื่อรับข่าวสารเกี่ยวกับเทคโนโลยีต่างๆ สามารถยกเลิกได้ทุกเมื่อ</p>

            <form className="form-inline d-flex justify-content-center align-items-center">
              <div className="form-group mb-2">
                <input type="text" className="form-control" placeholder="ระบุ Email ของท่าน" />
              </div>
              <button type="submit" className="btn">Subscribe</button>
            </form>
          </div>
        </div>
        <div className="images">
          <img src="_theme/img/check-1.png" alt="check" className="rocket-1 img-fluid" />
          <img src="_theme/img/check-2.png" alt="check" className="rocket-2 img-fluid" />
        </div>
      </section> */}

      <section className="offers ptb-120">
        <div className="container">
          <div className="heading text-center mb-70">
            <h2 className="h2">แนะนำฟีเจอร์สำหรับเว็บไซต์</h2>
            <p className="p-2">ให้เว็บไซต์รองรับผู้ใช้งานที่หลากหลาย และมีฟีเจอร์ที่ทันสมัย</p>
          </div>

          <div className="row">

            <div className="col-md-6">
              <div className="box d-flex align-items-center justify-content-start mb-30">
                <div className="icon">
                  <BiMessageRoundedCheck size="3em" color="#919CAD" />
                </div>
                <div className="text">
                  <h4 className="h4 mb-10">Multilingual</h4>
                  <p className="p-2">รองรับการแสดงผลหลายภาษา สำหรับธุรกิจที่มีกลุ่มลูกค้าต่างชาติ</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="box d-flex align-items-center justify-content-start mb-30">
                <div className="icon">
                  <MdOutlineDevices size="3em" color="#919CAD" />
                </div>
                <div className="text">
                  <h4 className="h4 mb-10">Responsive</h4>
                  <p className="p-2">รองรับการแสดงผลบนคอมพิวเตอร์, แทบเลต, โทรศัพท์มือถือ และอุปกรณ์ต่างๆ</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="box d-flex align-items-center justify-content-start">
                <div className="icon">
                  <HiOutlineLightBulb size="3em" color="#919CAD" />
                </div>
                <div className="text">
                  <h4 className="h4 mb-10">Creative Idea</h4>
                  <p className="p-2">สร้างสรรค์ระบบงานใหม่ๆ เพื่อให้ลูกค้าใช้งานระบบได้อย่างมีประสิทธิภาพ เพิ่มคุณค่าธุรกิจ</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="box d-flex align-items-center justify-content-start">
                <div className="icon">
                  <ImStatsDots size="3em" color="#919CAD" />
                </div>
                <div className="text">
                  <h4 className="h4 mb-10">Data Analytics</h4>
                  <p className="p-2">ติดตั้งเครื่องมือสำหรับวิเคราะห์ข้อมูล ช่วยให้เห็นภาพการเข้าใช้งานเว็บไซต์ เพื่อปรับเปลี่ยนแผนให้รองรับกับการใช้งาน</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {false ?
        <section className="articles">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-70">
                <h2 className="h2">บทความ ข่าวสารและกิจกรรม</h2>
                <p className="p-2">บทความให้ความรู้ต่างๆเกี่ยวกับซอฟต์แวร์ เทคโนโลยีต่างๆ ข่าวสารและกิจกรรมของเรา</p>
              </div>
            </div>

            <div className="row">

              {articles.map((item, index) => (
                <div className="col-lg-3 col-md-6">
                  <div className="card">
                    {/* <div className="bg-img bg-wide" style={{ backgroundImage: 'url(_theme/img/how-work/1.png)' }}></div> */}
                    <div className="card-body">
                      <h5 className="card-title">{item.attributes.name}</h5>
                      {/* <p className="card-text">Checklist สำหรับเตรียมข้อมูลให้พร้อม เพื่อส่งมอบให้ทีมพัฒนา</p> */}
                      <div className="text-right">
                        <Link to={`/Article/${item.attributes.slug}`} className="card-link text-right">ดูรายละเอียด</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>

            <div className="row mt-5">
              <div className="col-12 text-center">
                <Link to="/Article"><button className="btn btn-primary bg-color-02">รายละเอียดเพิ่มเติม</button></Link>
              </div>
            </div>

          </div>
        </section>
        : ''}

      <section className="ready ptb-120" id="article">
        <div className="container text-center">
          <div className="heading mb-30">
            <h2 className="h2-white">เทคโนโลยีที่เราเลือกใช้</h2>
          </div>

          <ul className="list-technology">
            <li>
              <FaPhp size={techIcon.size} color={techIcon.color} />
              <span>PHP</span>
            </li>
            <li>
              <DiMysql size={techIcon.size} color={techIcon.color} />
              <span>MySQL</span>
            </li>
            <li>
              <DiHtml5 size={techIcon.size} color={techIcon.color} />
              <span>HTML5</span>
            </li>
            <li>
              <DiCss3 size={techIcon.size} color={techIcon.color} />
              <span>CSS3</span>
            </li>
            <li>
              <DiJavascript size={techIcon.size} color={techIcon.color} />
              <span>JavaScript</span>
            </li>
            <li>
              <DiJqueryLogo size={techIcon.size} color={techIcon.color} />
              <span>jQuery</span>
            </li>
            <li>
              <DiJqueryUiLogo size={techIcon.size} color={techIcon.color} />
              <span>jQueryUI</span>
            </li>
            <li>
              <DiNodejsSmall size={techIcon.size} color={techIcon.color} />
              <span>NodeJS</span>
            </li>
            <li>
              <RiReactjsFill size={techIcon.size} color={techIcon.color} />
              <span>ReactJS</span>
            </li>
            <li>
              <RiVuejsFill size={techIcon.size} color={techIcon.color} />
              <span>VueJS</span>
            </li>
            <li>
              <SiStrapi size={techIcon.size} color={techIcon.color} />
              <span>Strapi</span>
            </li>
            <li>
              <DiLaravel size={techIcon.size} color={techIcon.color} />
              <span>Laravel</span>
            </li>
            <li>
              <AiOutlineSketch size={techIcon.size} color={techIcon.color} />
              <span>Sketch</span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Home;