import React, { Component } from 'react';
import parse from 'html-react-parser';
import withRouter from '../Components/WithRouter';
import Header from '../Components/Header';

export class ArticleView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: {}
    };
  }

  async componentDidUpdate(prevProps) {
    console.log("componentDidUpdate", prevProps, this.props);
    if (prevProps.params.slug !== this.props.params.slug) {
      var slug = this.props.params.slug;
      var url = "https://backend.increxion.com/api/articles?filters[slug][$eq]=" + slug;

      await fetch(url)
        .then(res => res.json())
        .then(result => {
          this.setState({ items: result.data[0] });
        });
    }
  }

  async componentDidMount() {
    var slug = this.props.params.slug;
    var url = "https://backend.increxion.com/api/articles?filters[slug][$eq]=" + slug;

    await fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        this.setState({ items: result.data[0] });
      });

  }

  render() {
    return (
      <>
        <Header
          topic={this.state.items.attributes ? this.state.items.attributes.name : ''}
          detail={this.state.items.attributes ? this.state.items.attributes.detail : ''} />
        <section className='pt-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {this.state.items.attributes ? parse(this.state.items.attributes.content) : ''}

{/* Company Content Template 
<div className='company-content'>
  <div className="row">
    <div className="col-sm-12 col-md-6">
      <div className="card">
        <img src="https://backend.increxion.com/uploads/img_337ba83b64.webp?updated_at=2022-11-04T08:29:08.957Z" className="card-img-top" alt="company"/>
      </div>
    </div>
    <div className="col-sm-12 col-md-6">
      <h5>บริษัท อินครีชั่น จำกัด (INCREXION CO., LTD.)</h5>
      <p>เราดำเนินธุรกิจด้านซอฟต์แวร์ เราให้คำปรึกษา ออกแบบ และพัฒนาระบบให้กับลูกค้า อาทิเช่น ออกแบบและพัฒนาเว็บไซต์, ระบบคลังสินค้า และออกระบบการจัดการธุรกิจให้เหมาะสม และตรงตามวัตถุประสงค์การใช้งาน เรามีการออกแบบและพัฒนาระบบจัดการข้อมูล (Back Office System) เพื่อให้ผู้ใช้งานสามารถเข้าไปจัดการกับข้อมูลได้สะดวกมากยิ่งขึ้น เพื่อให้บริษัทของท่านสามารถดำเนินกิจการได้อย่างรวดเร็ว และมีความน่าเชื่อถือ</p>
      <ul>
        <li>
          <h5>วิสัยทัศน์ (Vision)</h5>
          <ul className="mb-3">
            <li>พัฒนาและส่งมอบเทคโนโลยีใหม่ๆ ให้กับลูกค้าและสังคม</li>
          </ul>
        </li>
        <li>
          <h5>พันธกิจ (Mission)</h5>
          <ul className="mb-3">
            <li>เราส่งมอบซอฟต์แวร์ที่มีคุณภาพ มีประสิทธิภาพ ให้กับลูกค้า</li>
            <li>เราดำเนินกิจการด้วยความจริงใจ เที่ยงตรง และซื่อสัตย์ต่อวิชาชีพ</li>
            <li>เราให้บริการลูกค้าให้ได้รับความสะดวก และรวดเร็วมากที่สุด</li>
            <li>เราศึกษา และเรียนรู้เทคโนโลยีใหม่ๆ เพื่อนำมาพัฒนาต่อยอดธุรกิจให้มีประสิทธิภาพมากยิ่งขึ้น</li>
            <li>เราทุ่มเทความคิด ความสามารถ เพื่อให้ลูกค้าได้รับผลงานที่ดีที่สุด</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-12 col-md-6"></div>
  </div>
</div>
*/}


              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default withRouter(ArticleView)