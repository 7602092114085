import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Header = ({ topic, detail }) => {
  return (
    <>
      <section className="content-header pb-5">
        <Container>
          <Row>
            <Col><h2 className="h2">{topic}</h2></Col>
          </Row>
          <Row>
            <Col md={7} sm={12}>
              <h5 style={{ 'lineHeight': '1.4' }}>{detail}</h5>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Header;