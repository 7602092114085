import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Header from '../Components/Header'

const Package = () => {
  return (
    <>
      <Header topic={'ค่าบริการ'} detail={'เรามอบบริการที่หลากหลาย ในราคายืดหยุ่นตามความต้องการของคุณ ติดต่อเราวันนี้เพื่อรับคำแนะนำและข้อเสนอที่ดีที่สุด'} />
      <Container className='mt-5 mb-5 pt-5 pb-5'>
        <Row>
          <Col sm={12} md={4}>
            <Card className='mb-4 mt-0 mt-md-5'>
              <Card.Title className='mb-0 pt-3 px-4 text-center tx-color-primary'>
                <h3>Basic</h3>
                <p>สำหรับธุรกิจหรือองค์กรที่ต้องการเว็บไซต์เพื่อแสดงตัวตนในโลกออนไลน์</p>
              </Card.Title>
              <Card.Body className='pt-2'>
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>จำนวนหน้า</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        สูงสุด 5 หน้า
                        <small className='d-block fst-italic tx-color-light'>หรือ One Page 1 หน้า</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>การออกแบบ</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ใช้เทมเพลตพื้นฐาน
                        <small className='d-block fst-italic tx-color-light'>รองรับการแสดงผล Responsive</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>เนื้อหา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        จัดเตรียมโดยลูกค้า
                        <small className='d-block fst-italic tx-color-light'>ประกอบด้วยข้อความและรูปภาพ</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>SEO</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        พื้นฐาน
                        <small className='d-block fst-italic tx-color-light'>ปรับแต่งเมตาแท็ก, คีย์เวิร์ด, คำอธิบาย</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>โฮสติ้งและโดเมน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ฟรีค่าบริการปีแรก
                        <small className='d-block fst-italic tx-color-light'>หลังจากนั้นค่าบริการ 2,400 บาทต่อปี</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        2-3 สัปดาห์
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลารับประกัน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        3 เดือน
                        <small className='d-block fst-italic tx-color-light'>สำหรับการปรับปรุงเล็กน้อย</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer className='text-center'>
                <h5 className='mb-2'>ค่าบริการ 18,000 - 25,000 บาท</h5>
                <a href='tel:0959524556' className='text-decoration-none'>
                  <Button variant='outline-secondary' className='rounded-pill px-4'>ติดต่อสอบถาม</Button>
                </a>
              </Card.Footer>
            </Card>
          </Col>

          <Col sm={12} md={4}>
            <Card className='bg-color-secondary mb-4'>
              <Card.Title className='mb-0 pt-3 px-4 text-center text-white'>
                <h3>Premium</h3>
                <p className='text-white'>สำหรับธุรกิจหรือองค์กรที่ต้องการเว็บไซต์ที่ออกแบบสำหรับลูกค้าโดยเฉพาะ</p>
              </Card.Title>
              <Card.Body className='pt-2'>
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>จำนวนหน้า</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        สูงสุด 10 หน้า
                        <small className='d-block fst-italic tx-color-light'>หรือ One Page 1 หน้า</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>การออกแบบ</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ออกแบบตามแบรนด์ของลูกค้า
                        <small className='d-block fst-italic tx-color-light'>รองรับการแสดงผล Responsive</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>เนื้อหา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        จัดเตรียมโดยลูกค้า
                        <small className='d-block fst-italic tx-color-light'>ช่วยเหลือในการสร้างและปรับแต่งเนื้อหา</small>
                        <small className='d-block fst-italic tx-color-light'>ประกอบด้วยข้อความและรูปภาพ</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>SEO</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        พื้นฐาน
                        <small className='d-block fst-italic tx-color-light'>ปรับแต่งเมตาแท็ก, คีย์เวิร์ด, คำอธิบาย</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>โฮสติ้งและโดเมน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ฟรีค่าบริการปีแรก
                        <small className='d-block fst-italic tx-color-light'>หลังจากนั้นค่าบริการ 2,400 บาทต่อปี</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        4-8 สัปดาห์
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลารับประกัน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        6 เดือน
                        <small className='d-block fst-italic tx-color-light'>สำหรับการปรับปรุงเล็กน้อย</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer className='text-center'>
                <h5 className='mb-2 text-white'>ค่าบริการ 40,000 - 80,000 บาท</h5>
                <a href='tel:0959524556' className='text-decoration-none'>
                  <Button variant='outline-light' className='rounded-pill px-4'>ติดต่อสอบถาม</Button>
                </a>
              </Card.Footer>
            </Card>
          </Col>

          <Col sm={12} md={4}>
            <Card className='mb-4 mt-0 mt-md-5'>
              <Card.Title className='mb-0 pt-3 px-4 text-center tx-color-primary'>
                <h3>Custom</h3>
                <p>สำหรับธุรกิจหรือองค์กรที่ต้องการเว็บไซต์เฉพาะที่มีฟีเจอร์ขั้นสูง</p>
              </Card.Title>
              <Card.Body className='pt-2'>
                <ul class="list-group">
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>จำนวนหน้า</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ไม่จำกัด
                        <small className='d-block fst-italic tx-color-light'>ขึ้นอยู่กับความต้องการลูกค้า</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>การออกแบบ</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ออกแบบตามแบรนด์ของลูกค้า
                        <small className='d-block fst-italic tx-color-light'>รองรับการแสดงผล Responsive</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>เนื้อหา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        จัดเตรียมโดยลูกค้า
                        <small className='d-block fst-italic tx-color-light'>ช่วยเหลือในการสร้างและปรับแต่งเนื้อหา</small>
                        <small className='d-block fst-italic tx-color-light'>ประกอบด้วยข้อความและรูปภาพ</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>SEO</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        พื้นฐาน
                        <small className='d-block fst-italic tx-color-light'>ปรับแต่งเมตาแท็ก, คีย์เวิร์ด, คำอธิบาย</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>โฮสติ้งและโดเมน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ฟรีค่าบริการปีแรก
                        <small className='d-block fst-italic tx-color-light'>หลังจากนั้นค่าบริการ 2,400 บาทต่อปี</small>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลา</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        ขึ้นอยู่กับจำนวนหน้า
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item border-0">
                    <div className='d-flex' style={{ 'lineHeight': '1.2' }}>
                      <div className='me-auto tx-color-primary'>ระยะเวลารับประกัน</div>
                      <div className='text-end fw-bold tx-color-secondary'>
                        6 เดือน
                        <small className='d-block fst-italic tx-color-light'>สำหรับการปรับปรุงเล็กน้อย</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer className='text-center'>
                <h5 className='mb-0'></h5>
                <a href='tel:0959524556' className='text-decoration-none'>
                  <Button variant='outline-secondary' className='rounded-pill px-4'>ติดต่อสอบถาม</Button>
                </a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Package