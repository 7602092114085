import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export class Alert extends Component {
  constructor(props){
    super(props);

    this.state = {
      show: this.props.show
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.show !== prevState.show){
      this.setState({show: this.props.show});
    }
  }

  handleClose(){
    this.setState({show: false});
  }

  render() {
    return (
      <>
        <Modal show={this.state.show} onHide={() => this.handleClose()} animation={true}>
          <Modal.Header>
            <Modal.Title>{this.props.topic}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              {this.props.labelClose === undefined ? "ปิด" : this.props.labelClose}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default Alert