import React from 'react';
import Header from '../Components/Header';
import { Container, Row, Col } from 'react-bootstrap';
import { GoFileCode } from "react-icons/go";

const About = () => {
  const iconSize = '100%';

  // วันที่ก่อตั้งบริษัท
  const foundedDate = new Date('2016-11-18');
  const currentDate = new Date();

  // คำนวณจำนวนปีที่ก่อตั้ง
  const yearsInOperation = currentDate.getFullYear() - foundedDate.getFullYear();
  const isBeforeAnniversary = currentDate.getMonth() < foundedDate.getMonth() ||
    (currentDate.getMonth() === foundedDate.getMonth() && currentDate.getDate() < foundedDate.getDate());

  const totalYears = isBeforeAnniversary ? yearsInOperation - 1 : yearsInOperation;

  return (
    <>
      <Header topic={'เกี่ยวกับเรา'} detail={'เรามุ่งมั่นที่จะนำเสนอโซลูชันที่ตอบโจทย์ความต้องการของลูกค้า'} />
      <Container>
        <Row className='pt-5 mt-5'>
          <Col md={4} className='px-5'>
            <GoFileCode size={iconSize} color="#FA717B" />
          </Col>
          <Col>
            <h4>ประวัติของบริษัท</h4>
            <p>บริษัท อินครีชั่น จำกัด ก่อตั้งขึ้นเมื่อวันที่ 18 พฤศจิกายน 2016 ด้วยความมุ่งมั่นในการพัฒนาซอฟต์แวร์ที่ตอบสนองความต้องการเฉพาะของธุรกิจในยุคดิจิทัล</p>
            <p>ตลอดระยะเวลา {totalYears} ปีที่ผ่านมา เรามุ่งเน้นการให้บริการที่มีคุณภาพสูงและนำเสนอเทคโนโลยีล้ำสมัย เพื่อช่วยให้ลูกค้าก้าวข้ามความท้าทายทางเทคโนโลยีได้อย่างมีประสิทธิภาพ</p>
            <h4 className='mt-4'>พันธกิจและวิสัยทัศน์</h4>
            <p><b>พันธกิจ</b> ของเราคือการนำเทคโนโลยีที่ทันสมัยและมีประสิทธิภาพมาประยุกต์ใช้ในธุรกิจของลูกค้า เพื่อให้เกิดประโยชน์สูงสุด พร้อมทั้งมุ่งเน้นความเหมาะสมและสอดคล้องกับลักษณะเฉพาะของธุรกิจแต่ละประเภท เรามุ่งมั่นที่จะช่วยแก้ปัญหาต่างๆ ที่ลูกค้าเผชิญอยู่ ไม่ว่าจะเป็นการปรับปรุงกระบวนการทำงานให้มีประสิทธิภาพมากขึ้น ลดขั้นตอนที่ซับซ้อน และเพิ่มความสะดวกในการดำเนินงาน นอกจากนี้ เรายังให้ความสำคัญกับความถูกต้อง เสถียรภาพ และความรวดเร็วของระบบที่พัฒนา เพื่อให้ธุรกิจของลูกค้าสามารถทำงานได้อย่างราบรื่น และมีประสิทธิภาพในการตอบสนองความต้องการที่เปลี่ยนแปลงอย่างรวดเร็วในยุคดิจิทัล อีกทั้งยังสามารถเพิ่มขีดความสามารถในการแข่งขันในตลาดได้อย่างมั่นใจ</p>
            <p><b>วิสัยทัศน์</b> ของเรา คือการมอบประสบการณ์การใช้งานที่ยอดเยี่ยมแก่ลูกค้า โดยใช้เทคโนโลยีแก้ปัญหาและขับเคลื่อนธุรกิจให้เติบโตอย่างมั่นคง พร้อมพัฒนาโซลูชันที่เพิ่มประสิทธิภาพและเสริมศักยภาพในการแข่งขัน</p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default About