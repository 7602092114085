import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/core.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Popup from './Components/PDPA/Popup';
import Home from './Pages/Home';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import Contact from './Pages/Contact';
import UnderDevelop from './Pages/UnderDevelop';
import Service from './Pages/Service';
import About from './Pages/About';
import Package from './Pages/Package';

const App = () => {

  useEffect(() => {
    const evt = new Event("document-loaded");
    document.dispatchEvent(evt);
  }, []); // Empty array to run once after initial render

  return (
    <>
      <BrowserRouter basename={'/'}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Package" element={<Package />} />
          <Route path="/Portfolio" element={<UnderDevelop />} />
          <Route path="/Article" element={<Article />} />
          <Route path="/Article/:slug" element={<ArticleView />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/About" element={<About />} />
          {/* 
          <Route path="/FAQ" element={<ArticleView slug="faq" />} />
          <Route path="/TermCondition" element={<ArticleView slug="terms-and-conditions" />} />
          <Route path="/PrivacyPolicy" element={<ArticleView slug="privacy-policy" />} />
          <Route path="/CookiesPolicy" element={<ArticleView slug="cookies-policy" />} />
          */}
        </Routes>
        <Footer />
        <Popup />
      </BrowserRouter>
    </>
  );
};

export default App;