import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Header';

export class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  async componentDidMount() {
    const url = "https://backend.increxion.com/api/articles?filters[is_custom][$eq]=false";
    await fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log(result);
        this.setState({ items: result.data });
      });
  }

  render() {
    return (
      <>
        <Header topic="บทความ ข่าวสารและกิจกรรม" detail="ส่งต่อความรู้เทคโนโลยี, การวิเคราะห์และออกแบบระบบ, การพัฒนาซอฟต์แวร์, ข่าวสารและกิจกรรมต่างๆ" />
        <section className="pt-5">
          <div className="container">
            <div className="row">

              {this.state.items.map((item, index) => (
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  {/* <div className="bg-img bg-wide" style={{ backgroundImage: 'url(_theme/img/how-work/1.png)' }}></div> */}
                  <div className="card-body">
                    <h5 className="card-title">{item.attributes.name}</h5>
                    {/* <p className="card-text">Checklist สำหรับเตรียมข้อมูลให้พร้อม เพื่อส่งมอบให้ทีมพัฒนา</p> */}
                    <div className="text-right">
                      <Link to={`/Article/${item.attributes.slug}`} className="card-link text-right">ดูรายละเอียด</Link>
                    </div>
                  </div>
                </div>
              </div>
              ))}

            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Article