import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../Components/Header';
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhoneCall, FiMapPin } from 'react-icons/fi';
import { FaHome } from 'react-icons/fa';
import axios from 'axios';
import Alert from '../Components/Modal/Alert';

const Contact = () => {
  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData();
    formData.append('call', 'sent_message');
    formData.append('params', JSON.stringify(contact));

    axios({
      method: 'post',
      url: 'https://api.increxion.com/2.0/',
      data: formData
    })
      .then((res) => {
        console.log(res);
        setShowModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setContact({ ...contact, [id]: value });
  };

  return (
    <>
      <Alert
        show={showModal}
        topic="ส่งข้อความเรียบร้อย"
        message="ขอบคุณที่ติดต่อหาเรา ทีมงานได้รับข้อความเรียบร้อยและจะติดต่อกลับโดยเร็วที่สุด"
      />
      <Header
        topic="ติดต่อเรา"
        detail="เรายินดีให้คำปรึกษาด้านการออกแบบและพัฒนาซอฟต์แวร์ เว็บไซต์ และระบบเฉพาะทางต่างๆ ติดต่อเราได้ตามช่องทางด้านล่าง"
      />
      <section className="pt-5">
        <Container className='pt-5 pb-5'>
          <Row>
            <Col>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7752.522243231701!2d100.4105811571631!3d13.702628638411836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe384ea8d2ffeb57f!2sincrexion!5e0!3m2!1sth!2sth!4v1667607717637!5m2!1sth!2sth"
                style={{ border: 0, width: '100%', height: '50vh', marginBottom: '3rem' }}
                allowFullScreen=""
                title="company-map"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Col>
            <Col className='px-5'>
              <h4>ช่องทางติดต่อ</h4>
              <p>ท่านสามารถติดต่อเราผ่านทางช่องทางเหล่านี้</p>
              <ul className="list-nopadding mb-3">
                <li className="mb-2 d-flex flex-row">
                  <div className='mr-2'><FaHome /></div>
                  <div className="d-inline-block">
                    144/166 โครงการเดอะคอนเน็กอัพ3 (ซอย9) ซอยนาวีเจริญทรัพย์
                    ถนนกาญจนาภิเษก แขวงบางแค เขตบางแค กรุงเทพมหานคร 10160
                  </div>
                </li>
                <li className="mb-2 d-flex flex-row justify-content-md-start">
                  <div className='mr-2'><HiOutlineMail /></div>
                  <div className="d-inline-block">
                    <a href="mailto:increxion@gmail.com">increxion@gmail.com</a>
                  </div>
                </li>
                <li className="mb-2 d-flex flex-row justify-content-md-start">
                  <div className='mr-2'><FiPhoneCall /></div>
                  <div className="d-inline-block">
                    <a href="tel:095-952-4556">095-952-4556</a>
                  </div>
                </li>
                <li className="mb-2 d-flex flex-row justify-content-md-start">
                  <div className='mr-2'><FiMapPin /></div>
                  <div className="d-inline-block">
                    <a href="https://goo.gl/maps/DwbvAhR4KZ4VdcMr9" target="_blank" rel="noreferrer">แผนที่</a>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
