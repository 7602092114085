import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.css';

export class PDPAPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_accept: true
    };
  }

  handleClick(evt) {
    const form_data = new FormData();
    form_data.append("call", "popup_accept");

    axios({
      method: 'post', 
      url: '/api/webapi/', 
      data: form_data
    })
    .then((res) => {
      console.log(res);
      this.setState({is_accept: true});
    })
    .catch((err) => {
      console.log(err);
    });
  }

  componentDidMount() {
    const form_data = new FormData();
    form_data.append("call", "popup_check");

    axios({
      method: 'post', 
      url: '/api/webapi/', 
      data: form_data
    })
    .then((res) => {
      console.log("Popup : popup_check", res);
      this.setState({is_accept: res.data.results.data});
    })
    .catch((err) => {
      console.log(err);
    });
  }

  render() {
    return (
      <>
        {(!this.state.is_accept ? 
        <div className='pdpa-popup'>
          <div className="container">
            <div className="card mb-3">
              <div className="card-body">
                <div className='row'>
                  <div className="col-sm-12 col-md-10">
                    <h5>เว็บไซต์ของเรามีการใช้งานคุกกี้ “ยอมรับคุกกี้” เพื่อประสบการณ์ที่ดีในการเยี่ยมชมเว็บไซต์</h5>
                    <p>เราได้จัดทำนโยบายคุ้มครองความเป็นส่วนตัว เพื่อคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการทุกท่านที่เยี่ยมชมเว็บไซต์ <Link to="/Article/privacy-policy">อ่านเพิ่มเติมที่นี่</Link></p>
                  </div>
                  <div className="col-sm-12 col-md-2 text-right">
                    <button className="btn btn-primary bg-color-01" onClick={(evt) => {this.handleClick(evt)}}>ยอมรับ</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : '')}
      </>
    )
  }
}

export default PDPAPopup