import React from 'react';
import { Link } from 'react-router-dom';
import FreshImage from '../Components/FreshImage';

const Navbar = () => {
  // Function to handle scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll effect
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/#">
            <FreshImage src="/img/logo/logo.webp" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={scrollToTop}>หน้าแรก</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Service" onClick={scrollToTop}>บริการของเรา</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/About" onClick={scrollToTop}>เกี่ยวกับเรา</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Package" onClick={scrollToTop}>ค่าบริการ</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/Portfolio" onClick={scrollToTop}>ผลงาน</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/Article" onClick={scrollToTop}>บทความ</Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/Contact" onClick={scrollToTop}>ติดต่อเรา</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;